<template>
  <div class="user-input-rde">
    <RadioGroup
      v-model="multiSelectType"
      :items="[
        {
          label: 'User',
          value: 'user',
        },
        {
          label: 'Group User',
          value: 'group',
        },
      ]"
      @input="handleTypeChange"
    />
    <v-autocomplete
      v-if="multiSelectType === 'user'"
      v-model="values.selectedUsers"
      :items="user_items"
      outlined
      dense
      small-chips
      multiple
      clearable
      deletable-chips
      class="app-g-autocomplete-v2"
      label="User"
      :rules="[validateRequired]"
    />

    <v-autocomplete
      v-if="multiSelectType === 'group'"
      v-model="selectedUserGroups"
      :items="user_group_items"
      itemText="name"
      itemValue="members"
      outlined
      dense
      small-chips
      multiple
      clearable
      deletable-chips
      class="app-g-autocomplete-v2"
      label="Group User"
      :rules="[validateRequired]"
      @change="handleChangeGroup"
    />
  </div>
</template>

<script>
import RadioGroup from "@/components/compositions/RadioGroup/RadioGroup.vue";
import API from "@/service/api";

export default {
  props: {
    values: { type: Object },
    setValue: { type: Function, default: () => undefined },
    workspace: {
      type: Object,
      default: () => {},
    },
    required: {
      type: Boolean,
      default: true,
    },
  },
  components: { RadioGroup },
  data: () => ({
    multiSelectType: "user",
    selectedUserGroups: [],
    user_items: [],
    user_group_items: [],
  }),
  watch: {
    workspace: {
      immediate: true,
      handler(v) {
        if (v?.projectId) {
          this.fetchInitUserData(v?.projectId);
        }
      },
    },
  },
  methods: {
    validateRequired() {
      if (this.required && !this.values.selectedUsers?.length) {
        return "Please select at least one user";
      }
      return true;
    },
    fetchInitUserData(projectId) {
      if (projectId) {
        API.getProjectMemberList(projectId).then((member) => {
          this.user_items = member.map((user) => user.username);
        });
        API.getGroupList(projectId).then((groupList) => {
          this.user_group_items = groupList.map((group) => ({
            name: group.name,
            members: group.members,
          }));
        });
      }
    },
    handleChangeGroup() {
      this.setValue(
        "selectedUsers",
        this.selectedUserGroups?.join(",").split(",") || [],
      );
    },
    handleTypeChange() {
      this.setValue("selectedUsers", []);
      this.selectedUserGroups = [];
    },
  },
};
</script>

<style lang="scss">
.user-input-rde {
  .user-list {
    display: flex;
    gap: 5px;
    margin-top: 10px;
    flex-wrap: wrap;
    .user-chip {
      border-radius: 12px;
      background: var(--primary);
      font-size: 12px;
      font-weight: 400;
      padding: 0px 12px;
      line-height: 20px;
      color: white;
    }
  }
  .compositions__radio-group {
    label {
      color: black;
      font-weight: 500;
      padding-left: 10px;
    }
    .v-messages {
      display: none;
    }
  }
}
</style>
