import { cloneDeep, isNull } from "lodash";
import CommonUIControl from "@/helper/CommonUIControl";

export const SearchMixin = {
  data() {
    return {
      baseItems: [],
      filterItems: [],
    };
  },
  methods: {
    onFilter(params) {
      const searchConditions = params?.search;
      const sortConditions = params?.sort;
      if (params) {
        this.search(searchConditions, sortConditions);
      } else {
        this.filterItems = this.baseItems;
      }
    },
    search(searchParams, sortParams) {
      let searchItems = cloneDeep(this.baseItems);
      for (var key in searchParams) {
        if (!isNull(searchParams[key])) {
          searchItems = searchItems.filter(function (item) {
            if (item[key] === undefined || item[key] === null) return false;
            const dataType = typeof item[key];
            switch (dataType) {
              case "string": {
                return item[key]
                  .toLowerCase()
                  .includes(searchParams[key].toLowerCase());
              }
              case "boolean":
              case "number": {
                return item[key] == searchParams[key];
              }
              default:
                return false;
            }
          });
        }
      }

      if (sortParams) {
        this.filterItems = this.sort(
          searchItems,
          sortParams.sortField,
          sortParams.sortType,
        );
      } else {
        this.filterItems = searchItems;
      }
    },
    sort(items, field, asc = true) {
      let sortItems = [];

      if (items[0] && !(field in items[0])) {
        return items;
      }

      if (items.length > 1 && !isNull(field) && !isNull(items[0][field])) {
        const typeData = typeof items[0][field];
        if (typeData === "string") {
          if (asc) {
            sortItems = items.sort((a, b) => a[field].localeCompare(b[field]));
          } else {
            sortItems = items.sort((a, b) => b[field].localeCompare(a[field]));
          }
        } else {
          if (asc) {
            sortItems = items.sort((a, b) => (a[field] > b[field] ? 1 : -1));
          } else {
            sortItems = items.sort((a, b) => (a[field] < b[field] ? 1 : -1));
          }
        }
        return sortItems;
      } else {
        return items;
      }
    },
    confirmDelete(title, message, confirmAction) {
      CommonUIControl.confirm({
        title: title,
        message: message,
        onConfirm: async () => {
          await confirmAction();
        },
      });
    },
    doDefaultSort(fieldName) {
      if (fieldName != "") {
        this.onFilter({
          sort: {
            sortField: fieldName,
          },
        });
      }
    },
  },
};
