
<div ref="root">
  <div class="flex justify-between items-end pr-2">
    <v-btn-toggle v-model="viewType" tile color="deep-purple accent-3" group>
      <v-btn v-if="serviceTypes.length > 1" value=""> All </v-btn>
      <v-btn v-if="serviceTypes.includes('vscode')" value="vscodeserver">
        <SvgIcon iconName="vscode" size="sm" color="#000" class="mr-1" />
      </v-btn>
      <v-btn v-if="serviceTypes.includes('webssh')" value="sshserver">
        <SvgIcon iconName="ssh" size="md" color="#000" class="mr-1" />
      </v-btn>
      <v-btn v-if="serviceTypes.includes('notebook')" value="jupyter">
        <SvgIcon iconName="jupyter" size="md" color="#000" class="mr-1" />
      </v-btn>
    </v-btn-toggle>
    <IconButton
      iconName="mdi-clipboard-text-multiple-outline"
      tooltip="클립보드 복사"
      tooltipPosition="top"
      @click="handleCopyClipBoard"
    />
  </div>
  <MonacoEditor
    class="mt-2"
    language="yaml"
    :value="content"
    :miniMap="false"
    :readOnly="true"
    :disabledInteraction="true"
    :hideDetails="true"
    :height="height"
  />
</div>
