
<div
  class="w-auto relative inline-flex item-status-wrapper rounded-full"
  :style="{ 'background-color': bgColor }"
>
  <div class="flex flex-row px-2">
    <div class="status-text pr-1" :style="{ color: btColor }">
      {{ this.status }}
    </div>
    <div class="flex flex-col justify-center">
      <v-progress-circular
        v-if="
          status == POD_STATUS.updating ||
          status == POD_STATUS.installing ||
          status == POD_STATUS.restarting ||
          status == POD_STATUS.restart ||
          status == POD_STATUS.resume ||
          status == POD_STATUS.loading
        "
        indeterminate
        :size="18"
        width="2"
        class="ml-2"
        :color="btColor"
      ></v-progress-circular>
      <Tooltip
        v-else-if="status != 'Running' && tooltip != ''"
        :text="tooltip"
        position="right"
        targetSelf
        contentClass="bg-black"
      >
        <v-icon size="medium" :color="btColor"> mdi-alert-circle </v-icon>
      </Tooltip>
    </div>
  </div>
</div>
