import Vue from "vue";
import axios from "axios";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import store from "./store";
import router from "./router";
import helper from "./plugins/helper";

import VueObserveVisibility from "vue-observe-visibility";
import Keycloak, { keycloakOptions } from "./plugins/keycloak";

import "@mdi/font/css/materialdesignicons.css";
import "./styles/external/roboto.css";
import "./styles/App.scss";
import "./styles/StyleVariable";
import "./components/global/GlobalComponents";
//import "./styles/tailwind.css";
import "./styles/tailwind_out.css";
import versionChecker from "./plugins/versionChecker";
import pipelineProperty from "./plugins/pipelineProperty";
import featuresSwitch from "./plugins/featuresSwitch";

/** ===== 글로벌변수 ===== */
Vue.config.productionTip = false;
Vue.prototype.$appName = "MSA 개발 플랫폼";
Vue.prototype.$appSubName = "AMDP";
Vue.prototype.$appSubDesc = "(App Modernization Dev Platform)";

/** ===== setup msw ===== */
if (import.meta.env.MODE === "mock") {
  const { worker } = await import("./__mocks__/browser");
  worker.start({
    waitUntilReady: true,
    onUnhandledRequest: "bypass",
  });
}

(async () => {
  // # 키클락 관련 설정 동적으로 받아오기
  let keycloakConfig = {
    url: null,
    realm: null,
    clientId: null,
    useTopology: false,
  };

  keycloakConfig = (await axios.get("/v1/external/console-keycloak"))?.data
    ?.result;

  /** ===== plugins ===== */
  Vue.use(helper);
  Vue.use(VueObserveVisibility);
  Vue.use(featuresSwitch, keycloakConfig.useTopology);
  Vue.use(versionChecker);
  Vue.use(pipelineProperty);
  Vue.use(
    Keycloak,
    keycloakOptions({
      router,
      url: keycloakConfig.url,
      realm: keycloakConfig.realm,
      clientId: keycloakConfig.clientId,
      onReady() {
        /** ===== 초기화 ===== */
        new Vue({
          el: "#app",
          router,
          store,
          vuetify,
          render: (h) => h(App),
        }).$mount("#app");
      },
    }),
  );
})();
