<template>
  <div>
    <div>
      <Checkbox
        :value="values.vscode.useGit"
        label="Git"
        @input="onChangeUseGit"
      />
    </div>

    <div v-if="values.vscode.useGit === true">
      <Input
        v-model="values.vscode.git.id"
        label="* Git ID"
        appendClass="input-text-field-v2"
        placeholder="Enter your Git ID"
        :rules="[rules.required]"
      />
      <Input
        v-model="values.vscode.git.repository"
        appendClass="input-text-field-v2"
        label="* Repository"
        placeholder="Enter the repository"
        :rules="[rules.required, rules.gitUrl]"
      />
      <Input
        v-model="values.vscode.git.branch"
        appendClass="input-text-field-v2"
        label="* Branch"
        placeholder="Enter the branch"
        :rules="[rules.required]"
      />
      <Input
        v-model="values.vscode.git.token"
        appendClass="input-text-field-v2"
        label="Token"
        placeholder="Enter Token"
      />
    </div>
    <div>
      <Checkbox v-model="values.useExtention" label="Extension" />
    </div>
    <v-autocomplete
      v-if="values.useExtention === true"
      v-model="values.packageType"
      label="Extention packages"
      :items="extentionPackageOptions"
      itemText="displayName"
      itemValue="name"
      outlined
      dense
      small-chips
      multiple
      clearable
      deletable-chips
      class="app-g-autocomplete-v2"
      :rules="[rules.required]"
    >
      <template v-slot:item="data">
        <v-list-item v-bind="data.attrs" @click="toggleSelection(data.item)">
          <v-list-item-action>
            <v-checkbox
              v-model="data.attrs.inputValue"
              :value="data.item.value"
              :ripple="false"
            ></v-checkbox>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              {{ data.item.displayName }}
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ data.item.description }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-autocomplete>
    <!-- currently, we hide install files -->
    <div v-if="false">
      <Checkbox v-model="values.vscode.useFiles" label="Install Files" />
    </div>
    <v-autocomplete
      v-if="false"
      v-model="values.vscode.files"
      label="Add Files"
      :items="[
        { label: 'File 1', value: 'abc' },
        { label: 'File 2', value: 'bdf' },
      ]"
      :itemText="(a) => a.label"
      :itemValue="(a) => a.value"
      outlined
      dense
      small-chips
      multiple
      clearable
      deletable-chips
      class="rde-autocomplete"
      :rules="[rules.required]"
    />
  </div>
</template>

<script>
import Checkbox from "@/components/atoms/Checkbox/Checkbox.vue";
import Input from "@/components/atoms/Input/Input.vue";
import { RulesMixin } from "@/mixins/RulesMixin";
import { getExtentionRDEList } from "@/service/apis/workspaceApis";

export default {
  props: {
    values: { type: Object },
    setValue: { type: Function, default: () => undefined },
  },
  components: { Checkbox, Input },
  mixins: [RulesMixin],
  data: () => ({
    addType: "",
    extentionPackageOptions: [],
  }),
  methods: {
    onChangeUseGit(value) {
      this.setValue("vscode.useGit", value);
      if (value && !this.values.vscode.git) this.setValue("vscode.git", {});
    },
    toggleSelection(item) {
      if (
        !this.values?.packageType?.length ||
        this.values?.packageType?.every((i) => i !== item.name)
      ) {
        this.setValue("packageType", [...this.values.packageType, item.name]);
      } else {
        this.setValue(
          "packageType",
          this.values.packageType?.filter((i) => i !== item.name),
        );
      }
    },
  },
  mounted() {
    getExtentionRDEList().then((res) => {
      this.extentionPackageOptions = res?.data?.data || [];
    });
  },
};
</script>

<style lang="scss"></style>
