<template>
  <v-form ref="form" v-model="valid">
    <div class="w-full flex flex-row container-search-dashboard">
      <label v-if="(searchFields?.length || 0) > 1" class="label mt-2 mr-2">
        Search by
      </label>
      <div class="inline-grid grid-cols-2 gap-2">
        <Select
          v-if="(searchFields?.length || 0) > 1"
          v-model="searchFieldSelected"
          label="Search by"
          :items="searchFields"
          :itemText="(a) => a.label || a"
          :itemValue="(a) => a.value"
          outlined
          dense
          class="bg-autocomplete"
          @change="(value) => onChangeSearchOption()"
        />
        <FInput
          v-model="inputSearch"
          :append-icon="'mdi-magnify'"
          outlined
          dense
          small-chips
          placeholder="Search"
          label="Search"
          size="lg"
          class="bg-autocomplete"
          @input="debounceInput"
        />
      </div>
      <div class="col-end-6 flex flex-row ml-auto gap-2">
        <template v-if="sortFields">
          <FMenu
            :items="sortFields"
            label="SORT"
            :onSelected="onSelectedSort"
          />
          <div class="search-indicator">
            <FButton size="lg" color="primary" @click="onChangeSortType()">
              <v-icon v-if="sortType">mdi-sort-ascending</v-icon>
              <v-icon v-else>mdi-sort-descending</v-icon>
            </FButton>
          </div>
        </template>
        <slot></slot>
      </div>
    </div>
  </v-form>
</template>

<script lang="ts">
import FButton from "@/components/fatoms/FButton/FButton.vue";
import FInput from "@/components/fatoms/FInput/FInput.vue";
import FMenu from "@/components/newWorkspace/components/WorkspaceList/FMenu.vue";
import Select from "@/components/atoms/Select/Select.vue";
import * as _ from "lodash";

const SORT_TYPE = {
  ASCENDING: true,
  DESCENDING: false,
};

export default {
  props: {
    onSearch: { type: Function, default: () => {} },
    sortFields: { type: Array },
    searchFields: { type: Array },
    isFavorite: { type: Boolean, default: false },
  },
  components: { FButton, FInput, FMenu, Select },
  data: () => {
    return {
      inputSearch: null,
      valid: true,
      searchFieldSelected: "",
      sortType: SORT_TYPE.ASCENDING,
      sortField: null,
    };
  },
  watch: {
    isFavorite: {
      immediate: true,
      handler() {
        this.handleClickFavorite();
      },
    },
  },
  methods: {
    hanldeSearch() {
      const searchParams = this.buildSearchParams();
      this.onSearch(searchParams);
    },
    handleClickSort() {
      if (this.sortField) {
        const searchParams = this.buildSearchParams();
        this.onSearch(searchParams);
      }
    },
    onChangeSortType() {
      this.sortType = !this.sortType;
      this.handleClickSort();
    },
    onSelectedSort(selectedItem) {
      this.sortField = selectedItem;
      this.handleClickSort();
    },
    buildSearchParams() {
      const searchObj = {};
      searchObj[this.searchFieldSelected] = this.inputSearch;
      let searchParams = {
        search: searchObj,
        sort: {
          sortType: this.sortType,
          sortField: this.sortField,
        },
      };

      if (this.isFavorite) {
        searchParams.search = {
          ...searchParams.search,
          ...{
            favorite: this.isFavorite,
          },
        };
      }
      return searchParams;
    },
    onChangeSearchOption() {
      if (this.searchFieldSelected) {
        this.hanldeSearch();
      }
    },
    debounceInput: _.debounce(function () {
      this.hanldeSearch();
    }, 800),
    handleClickFavorite() {
      const searchParams = this.buildSearchParams();
      this.onSearch(searchParams);
    },
  },
  mounted() {
    if (this.searchFields && !_.isNull(this.searchFields[0])) {
      this.searchFieldSelected = this.searchFields[0]?.value || null;
    }
  },
};
</script>

<style lang="scss">
.container-search-dashboard {
  background: transparent !important;
  padding: 10px 0px;

  .search-indicator {
    button {
      padding: 0 5px !important;
      height: 40px !important;
      min-width: 40px !important;
    }
  }

  .bg-autocomplete {
    .v-text-field__details {
      display: none;
    }
    fieldset {
      background-color: white !important;
    }
    .v-select__slot,
    .v-input__slot {
      min-height: 40px !important;
    }
    .v-input__slot {
      margin: 0px;
      margin-bottom: 0px !important;
    }

    &.v-text-field--outlined {
      .v-select__slot,
      .v-text-field__slot {
        .v-label {
          top: 11px !important;
          font-size: 14px;
          z-index: 1;
          background: transparent;
          color: rgba(0, 0, 0, 0.6);
        }
      }
    }
    .v-select__selections {
      max-height: 30px;
      overflow: auto;
    }
  }

  .label {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    text-align: left;
  }
}
</style>
