<template>
  <div>
    <label class="label-infra-size"> Infra Size</label>
    <div class="grid grid-cols-3 gap-x-2">
      <NumberInput
        v-model="values[serviceType].resourceSize.cpu"
        label="CPU - milicores"
        :format="'none'"
        appendClass="input-text-field-v2"
        :rules="[rules.required, validateTotalSize('cpu')]"
        @blur="validate"
      />
      <NumberInput
        v-model="values[serviceType].resourceSize.memory"
        label="Memory - MiB"
        :format="'none'"
        appendClass="input-text-field-v2"
        :rules="[rules.required, validateTotalSize('memory')]"
        @blur="validate"
      />
    </div>
  </div>
</template>

<script>
import NumberInput from "@/components/atoms/NumberInput/NumberInput.vue";
import { RulesMixin } from "@/mixins/RulesMixin";

export default {
  props: {
    serviceType: { type: String },
    values: { type: Object },
    requirementInfraResource: { type: Array },
    validate: { type: Function },
  },
  components: { NumberInput },
  mixins: [RulesMixin],
  methods: {
    validateTotalSize(name) {
      return (value) => {
        if (this.requirementInfraResource) {
          const selectedMinimum = this.requirementInfraResource?.find(
            (r) => r?.serviceType === this.serviceType,
          )?.[name]?.value;
          if (selectedMinimum && value < selectedMinimum) {
            return `Minimum ${name} of ${this.serviceType} is ${selectedMinimum}`;
          }
        }

        const totalSize = Number(this.values.resourceSize[name]);

        const currentTotal = this.values.serviceTypes.reduce(
          (total, serviceType) => {
            return serviceType !== this.serviceType
              ? total + Number(this.values[serviceType].resourceSize[name])
              : total + Number(value);
          },
          0,
        );

        if (currentTotal > totalSize)
          return `Total ${name} of the service type exceeds ${totalSize}`;

        return true;
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.label-infra-size {
  font-weight: 500;
  font-size: 12px;
  margin-bottom: 10px;
  margin-left: 5px;
}
</style>
