import { cloneDeep, get, isEmpty, omit } from "lodash";
import {
  CONVERT_TIME_UNIT,
  DATETIME_FORMAT,
  FIELD_EXPORT_CONFIG,
  PR_TYPE,
  mapRequestCICDFields,
} from "./constant";
import moment from "moment";
import CommonUIControl from "@/helper/CommonUIControl";
import { exportFile } from "@/helper/papaparseHelper";

export const transformParamsRequest = (filterState) => {
  const finalResult = {};
  Object.keys(mapRequestCICDFields).forEach((key) => {
    const value = get(filterState, key);
    if (value || typeof value === "boolean") {
      if (["fromDate", "toDate"].includes(mapRequestCICDFields[key])) {
        // Date param should be ISO STRING DATE
        finalResult[mapRequestCICDFields[key]] = moment(value).toISOString();
      } else if (["type"].includes(mapRequestCICDFields[key])) {
        finalResult[mapRequestCICDFields[key]] = value
          ? PR_TYPE.NON_PR
          : PR_TYPE.PR;
      } else {
        finalResult[mapRequestCICDFields[key]] = value;
      }
    }
  });
  return finalResult;
};

export const sortArrayByTimetimestamp = (arr, field = "timestamp") => {
  return (
    arr?.sort((a, b) => {
      const timestampA = moment(a[field]);
      const timestampB = moment(b[field]);

      // Compare the timestamps in descending order
      return timestampA.diff(timestampB);
    }) || []
  );
};

export const specifyDurationBreak = ({
  startTime: startTimeParam,
  endTime: endTimeParam,
  pipelines,
}) => {
  let startTime = pipelines?.[0]?.timestamp || startTimeParam;
  let endTime = pipelines?.slice?.(-1)?.[0]?.timestamp || endTimeParam;

  if (startTime && endTime) {
    const resultInDays = moment(new Date(endTime)).diff(
      moment(new Date(startTime)),
      "days",
    );

    if (resultInDays > 31) {
      return {
        unit: "month",
        displayFormats: {
          month: "MM/YYYY",
        },
      };
    }

    if (resultInDays > 1) {
      return {
        unit: "day",
        displayFormats: {
          day: "DD/MM",
        },
      };
    }
  }

  return {
    unit: "hour",
    displayFormats: {
      hour: "HH:mm",
    },
  };
};

export const PIPELINE_ITEM_TOOLTIP_DEFAULT = {
  callbacks: {
    title: (tooltipItems) => {
      return `App Name: ${get(tooltipItems, "[0].raw.appName") || "N/A"}`;
    },
    label: function (context) {
      let label = context.dataset.label || "";
      return `${label} : ${context?.raw?.y || context?.formattedValue}m`;
    },
    footer: (tooltipItems) => {
      return `Excuted Time: ${moment(
        get(tooltipItems, "[0].raw.timestamp"),
      ).format(DATETIME_FORMAT)}`;
    },
  },
  backgroundColor: "rgba(0, 0, 0, 0.6)",
};

export const genAgoTime = (minutes, currentDate) => {
  return new Date((currentDate || new Date()).getTime() - minutes * 60000);
};

const numberView = (value) => (value >= 10 ? value : `0${value}`);

export const formatDuration = (seconds) => {
  const duration = moment.duration(seconds, "seconds");

  const hours = Math.floor(duration.asHours());
  const minutes = duration.minutes();
  const remainingSeconds = duration.seconds();

  const formattedDuration = `${
    hours ? `${numberView(hours)}h ` : ""
  } ${numberView(minutes)}m ${numberView(remainingSeconds)}s`;

  return formattedDuration;
};

export const customFromNow = (date) => {
  const pastDate = moment(new Date(date));
  const duration = moment.duration(moment().diff(pastDate));

  const days = Math.floor(duration.asDays());
  const hours = duration.hours();
  const minutes = duration.minutes();
  const seconds = duration.seconds();

  let result = "";

  if (days > 0) result += days + "d ";
  if (hours > 0) result += hours + "h ";
  if (minutes > 0) result += numberView(minutes) + "m ";
  if (seconds > 0) result += numberView(seconds) + "s ";

  return result + "ago";
};

export const roundNumber = (number, digit = 2) => Number(number.toFixed(digit));

export const genHourOnlyFormatfunc = (date, type = "hour") => {
  let value = moment(new Date(date))
    .set("minutes", 0)
    .set("seconds", 0)
    .set("milliseconds", 0);

  if (type === "day") {
    value.set("hours", 0);
  } else if (type === "month") {
    value.set("hours", 0);
    value.set("date", 1);
  } else if (type === "week") {
    value = value.clone().startOf("isoWeek");
  } else if (type === "year") {
    return moment(value).format("YYYY");
  }

  return value.toDate();
};
export const dateDiffIn = (startTime, endTime, type = "days") => {
  return (
    Math.ceil(
      moment(new Date(endTime)).diff(
        moment(new Date(startTime)),
        type,
        type === "months",
      ),
    ) + 1
  );
};

export const transformDataToExportCommon = (data, options, fileName) => {
  let transformData;

  const omitFields = (finalLine) => {
    const newFinalLine = cloneDeep(finalLine);
    Object.keys(newFinalLine).forEach((key) => {
      if (!newFinalLine[key] && newFinalLine[key] !== 0) {
        newFinalLine[key] = "";
      }

      if (
        (typeof newFinalLine[key] === "object" &&
          !(newFinalLine[key] instanceof Date)) ||
        Array.isArray(newFinalLine[key]) ||
        newFinalLine[key] === "Duration" ||
        key === "x"
      ) {
        delete newFinalLine[key];
      }
    });
    return newFinalLine;
  };

  if (data.datasets?.length && !isEmpty(options)) {
    // this is data from chart
    transformData = data.datasets?.reduce((final, cur) => {
      return final.concat(
        cur?.data?.map((lineParam, index) => {
          let line = cloneDeep(lineParam);

          if (typeof line === "number") {
            line = cur?.pipelines?.[index];
            line.y = roundNumber(line.duration / CONVERT_TIME_UNIT, 2);
          }

          let finalLine = {};

          if (FIELD_EXPORT_CONFIG[fileName]) {
            for (const [key, value] of Object.entries(
              FIELD_EXPORT_CONFIG[fileName],
            )) {
              finalLine[value] = line[key];
            }
          } else {
            finalLine = omit(
              {
                ...line,
                label: cur?.label || line?.label,
              },
              ["x", "y"],
            );

            const titleX = options?.scales?.x?.title?.text || "x";
            const titleY = options?.scales?.y?.title?.text || "y";

            finalLine[titleX] = line.x;
            finalLine[titleY] = line.y;
          }
          return omitFields(finalLine);
        }),
      );
    }, []);
  } else if (data?.length) {
    // this is normal list data
    transformData = data?.map((line) => {
      let newLine = {};
      if (FIELD_EXPORT_CONFIG[fileName]) {
        for (const [key, value] of Object.entries(
          FIELD_EXPORT_CONFIG[fileName],
        )) {
          newLine[value] = line[key];
        }
      } else {
        newLine = line;
      }

      return newLine;
    });
  }

  return transformData;
};

// This function exports common data as MVP for the export feature.
// In the future, we can add more details to each chart.
export const exportCommonChartFunc = (data, options, formatData, fileName) => {
  try {
    const transformData = transformDataToExportCommon(
      data,
      options,
      formatData,
    );
    exportFile(transformData, fileName || formatData);
  } catch (error) {
    CommonUIControl.ShowWarningToast("Parsing data is failed!");
  }
};
