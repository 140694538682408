import RdeContainerPage from "../pages/RdeContainer/RdeContainerPage.vue";

export default [
  {
    path: "rde-containers",
    component: RdeContainerPage,
    meta: {
      title: "RDE Containers",
      isMenuItem: true,
      breadCrumb: [{ text: "RDE Containers" }],
    },
  },
];
