import httpClient from "../httpClient";
import qs from "qs";
import CommonUIControl from "@/helper/CommonUIControl";

export default {
  // get dashboard 1 -  v1/dashboard/cicd-dashboard
  getCICDDashboard(params) {
    return new Promise((resolve, reject) => {
      httpClient
        .get("dashboard/cicd-dashboard", {
          params,
          paramsSerializer: (params) => {
            return qs.stringify(params, { arrayFormat: "repeat" });
          },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          CommonUIControl.ShowErrorToast(
            err?.message || "Fetching data failed",
          );
          reject(err.response);
        });
    });
  },
  // get dashboard 1 for 1 app detail
  getCICDAppDetailDashboard(appId, params = { builds: 10 }) {
    return new Promise((resolve, reject) => {
      httpClient
        .get(`dashboard/cicd-dashboard/${appId}`, {
          params,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          CommonUIControl.ShowErrorToast(
            err?.message || "Fetching data failed",
          );
          reject(err.response);
        });
    });
  },
  // get pods by application
  getPodsDashboard({ clusterId, namespace, name }) {
    return new Promise((resolve, reject) => {
      httpClient
        .get("/dashboard/kubernetes-dashboard/pods", {
          params: { clusterId, namespace, name },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          CommonUIControl.ShowErrorToast(
            err?.message || "Fetching data failed",
          );
          reject(err.response);
        });
    });
  },

  // get dashboard 2 -  Kubernetes
  getKubernetesDashboard(params) {
    return new Promise((resolve, reject) => {
      httpClient
        .get("/dashboard/kubernetes-dashboard", {
          params,
          paramsSerializer: (params) => {
            return qs.stringify(params, { arrayFormat: "repeat" });
          },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          CommonUIControl.ShowErrorToast(
            err?.message || "Fetching data failed",
          );
          reject(err.response);
        });
    });
  },

  // get Groups by project
  getServiceTypesDashboard(params) {
    return new Promise((resolve, reject) => {
      httpClient
        .get("/dashboard/filter/service-types", {
          params,
          paramsSerializer: (params) => {
            return qs.stringify(params, { arrayFormat: "repeat" });
          },
          baseURL:
            process.env.VUE_APP_DASHBOARD_API_HOST_URL ||
            "https://ags-dashboard.amdp-dev.skamdp.org",
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          CommonUIControl.ShowErrorToast(
            err?.message || "Fetching data failed",
          );
          reject(err.response);
        });
    });
  },
  // get dashboard DORA -  v1/dashboard/dora-dashboard
  getDORADashboard(params) {
    return new Promise((resolve, reject) => {
      httpClient
        .get("dashboard/devops-dashboard", {
          params,
          paramsSerializer: (params) => {
            return qs.stringify(params, { arrayFormat: "repeat" });
          },
          baseURL:
            process.env.VUE_APP_DASHBOARD_API_HOST_URL ||
            "https://ags-dashboard.amdp-dev.skamdp.org",
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          CommonUIControl.ShowErrorToast(
            err?.message || "Fetching data failed",
          );
          reject(err.response);
        });
    });
  },
  getWorkspaceDetailMetric({
    workspaceId,
    type = "CURRENT",
    step = null,
    duration,
    from = null,
    to = null,
  }) {
    return new Promise((resolve, reject) => {
      httpClient
        .get("/dashboard/rde-dashboard/workspace-detail-metrics", {
          params: { workspaceId, type, step, duration, from, to },
          baseURL:
            process.env.VUE_APP_DASHBOARD_API_HOST_URL ||
            "https://ags-dashboard.amdp-dev.skamdp.org",
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          CommonUIControl.ShowErrorToast(
            err?.message || "Fetching data failed",
          );
          reject(err.response);
        });
    });
  },
  getWorkspaceListMetric({ workspaceIds }) {
    return new Promise((resolve, reject) => {
      httpClient
        .get("/dashboard/rde-dashboard/workspace-list-metrics", {
          params: { workspaceIds },
          baseURL:
            process.env.VUE_APP_DASHBOARD_API_HOST_URL ||
            "https://ags-dashboard.amdp-dev.skamdp.org",
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          CommonUIControl.ShowErrorToast(
            err?.message || "Fetching data failed",
          );
          reject(err.response);
        });
    });
  },
  getDevopsMetricConfig(params) {
    return new Promise((resolve, reject) => {
      httpClient
        .get("/dashboard/devops-dashboard/devops-metric-config/get", {
          params,
          paramsSerializer: (params) => {
            return qs.stringify(params, { arrayFormat: "repeat" });
          },
          baseURL:
            process.env.VUE_APP_DASHBOARD_API_HOST_URL ||
            "https://ags-dashboard.amdp-dev.skamdp.org",
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          CommonUIControl.ShowErrorToast(
            err?.message || "Fetching data failed",
          );
          reject(err.response);
        });
    });
  },
  saveDevopsMetricConfig(data) {
    return new Promise((resolve, reject) => {
      httpClient
        .post(
          `/dashboard/devops-dashboard/devops-metric-config/create-or-update`,
          data,
          {
            baseURL:
              process.env.VUE_APP_DASHBOARD_API_HOST_URL ||
              "https://ags-dashboard.amdp-dev.skamdp.org",
          },
        )
        .then((resp) => {
          if (resp && resp.data) {
            resolve(resp.data);
          } else {
            reject(resp);
          }
        })
        .catch((e) => {
          reject(e.response || e);
        });
    });
  },
  deleteDevopsMetricConfig(params) {
    return new Promise((resolve, reject) => {
      httpClient
        .delete(`/dashboard/devops-dashboard/devops-metric-config/delete`, {
          params,
          baseURL:
            process.env.VUE_APP_DASHBOARD_API_HOST_URL ||
            "https://ags-dashboard.amdp-dev.skamdp.org",
        })
        .then((resp) => {
          if (resp?.status === 200) {
            resolve(resp);
          } else {
            reject(resp);
          }
        })
        .catch((e) => {
          reject(e.response || e);
        });
    });
  },
  getRdeMetrics() {
    return new Promise((resolve, reject) => {
      httpClient
        .get("/dashboard/rde-dashboard/rde-list-metrics", {
          baseURL:
            process.env.VUE_APP_DASHBOARD_API_HOST_URL ||
            "https://ags-dashboard.amdp-dev.skamdp.org",
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          CommonUIControl.ShowErrorToast(
            err?.message || "Fetching data failed",
          );
          reject(err.response);
        });
    });
  },
};
