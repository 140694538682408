<template>
  <section>
    <div class="flex flex-col">
      <div class="w-full ml-1">
        <SearchBar
          :onSearch="onSearch"
          :searchFields="searchFields"
          :isFavorite="isFavorite"
        >
          <div class="flex flex-row gap-2 mr-2">
            <FButton
              v-if="showFavoriteBtn"
              size="md"
              fill
              color="white"
              class="text-black"
              @click="() => handleClickFavorite()"
            >
              <v-icon v-if="isFavorite">mdi-star</v-icon>
              <v-icon v-else>mdi-star-outline</v-icon>
              Show Favorite
            </FButton>
            <FButton
              v-if="showSelectBtn"
              :disabled="selectedItems.length <= 0"
              size="md"
              color="primary"
              @click="() => onClickDelete()"
            >
              <v-icon>mdi-delete</v-icon>
              Delete
            </FButton>
            <FButton
              v-if="showAddBtn"
              size="md"
              fill
              depressed
              color="primary"
              @click="() => onClickAdd()"
            >
              <v-icon>mdi-plus</v-icon>
              Add RDE
            </FButton>
          </div>
        </SearchBar>
      </div>
      <div class="p-1">
        <WebIdeTable
          :items="filterItems"
          :showFavoriteBtn="showFavoriteBtn"
          :showSelectBtn="showSelectBtn"
          :onFavorite="onFavorite"
          :viewStateRDE="viewStateRDE"
          :viewResourceRDE="viewResourceRDE"
          @click:remove="handleRemoveItem"
          @click:stop="handleStatusItem"
          @click:resume="handleStatusItem"
          @click:shutdown="handleStatusItem"
          @click:restart="handleStatusItem"
          @selectedws="updateSelectedItems"
          @click:forceRestart="handleForceRestart"
          @getting-rde-status-error="onRdeStatusError"
        />
      </div>
    </div>
    <div
      :class="{
        'app-rde-popup-detail': true,
        'app-rde-popup-detail-ative': !!selectedApp,
      }"
    >
      <RDELogPopup
        v-if="!!selectedApp"
        :turnOffFunc="() => (selectedApp = null)"
        :selectedApp="selectedApp"
      />
    </div>
    <keep-alive>
      <Dialog
        v-model="showResourceRDE"
        persistent
        title="Resource Detail"
        max-width="700"
        @input="() => (selectedResourceRDE = null)"
      >
        <ResourceDetailRDE
          v-if="!!selectedResourceRDE"
          :selectedResourceRDE="selectedResourceRDE"
        />
      </Dialog>
    </keep-alive>
  </section>
</template>

<script>
import SearchBar from "@/components/newWorkspace/common/SearchBar.vue";
import WebIdeTable from "./components/WebIdeTable.vue";
import { SearchMixin } from "@/mixins/SearchMixin";
import { ProfileAuthMixin } from "@/mixins/ProfileAuthMixin";
import { RequestMixin } from "@/mixins/RequestMixin";
import { mapState } from "vuex";
import CommonUIControl from "@/helper/CommonUIControl";
import RDELogPopup from "@/pages/newWorkspace/RDELogPopup.vue";
import ResourceDetailRDE from "@/pages/newWorkspace/ResourceDetailRDE.vue";

import {
  updateRdeStatus,
  removeWorkspaceRde,
  forceRestartRde,
} from "@/service/apis/workspaceApis";
import { isNull, cloneDeep } from "lodash";
import { ROLE_TYPE } from "@/service/constants";

export default {
  props: {
    items: { type: Array },
    showFavoriteBtn: { type: Boolean, default: false },
    showSelectBtn: { type: Boolean, default: false },
    showAddBtn: { type: Boolean, default: false },
    favoriteKey: { type: String, default: "_fv_key" },
  },
  components: { SearchBar, WebIdeTable, RDELogPopup, ResourceDetailRDE },
  mixins: [SearchMixin, ProfileAuthMixin, RequestMixin],
  data() {
    return {
      selectedItems: [],
      searchFields: null,
      workspaceId: null,
      workspace: {},
      isFavorite: false,
      favoriteItems: [],
      selectedApp: null,
      selectedResourceRDE: null,
      showResourceRDE: false,
    };
  },
  computed: {
    ...mapState({
      accountInfo: (state) => state.accountInfo,
      workspaceList: (state) => state.workspace.workspaceList,
      roleList: (state) => state.accountInfo.roleList,
    }),
    isOwner() {
      if (this.isAdmin) return true;
      else {
        const projectId = this.workspace?.projectId;
        return this.roleList.some(
          (item) =>
            item.project.id == projectId && item.role == ROLE_TYPE.OWNER,
        );
      }
    },
  },
  watch: {
    items: {
      immediate: true,
      handler(v) {
        this.baseItems = v;
        if (this.baseItems?.length > 0) {
          this.filterItems = v;
          this.getFavoritesFromStorage();
        } else {
          this.filterItems = [];
        }
      },
    },
  },
  methods: {
    onSearch(params) {
      this.onFilter(params);
    },
    handleRemoveItem(item) {
      CommonUIControl.confirm({
        title: "원격 개발 환경 삭제",
        message: "정말 삭제하시겠습니까?",
        onConfirm: async () => {
          await this.deleteItem(item.id);
        },
      });
    },
    async handleStatusItem(item, action) {
      await this.mutation(
        () =>
          updateRdeStatus({
            rdeId: item.id,
            status: action,
          }),
        {
          onSuccess: () => {
            // update item status
            const index = this.filterItems.findIndex(
              (env) => env.id === item.id,
            );
            if (index !== -1) {
              this.filterItems[index].status = action;
            }
          },
          onError: (err) => {
            console.log(err);
          },
        },
      );
    },
    async handleForceRestart(item, action) {
      await this.mutation(
        () =>
          forceRestartRde({
            rdeId: item.id,
          }),
        {
          onSuccess: () => {
            // update item status
            const index = this.filterItems.findIndex(
              (env) => env.id === item.id,
            );
            if (index !== -1) {
              this.filterItems[index].status = action;
            }
          },
          onError: (err) => {
            console.log(err);
          },
        },
      );
    },
    onClickDelete() {
      CommonUIControl.confirm({
        title: "원격 개발 환경 삭제",
        message: "정말 삭제하시겠습니까?",
        onConfirm: async () => {
          const deleteIds = cloneDeep(this.selectedItems);
          if (deleteIds && deleteIds.length > 0) {
            this.deleteItem(deleteIds);
          }
        },
      });
    },
    async deleteItem(id) {
      if (!id?.length) return;
      const ids = Array.isArray(id) ? id : [id];

      CommonUIControl.ShowUIProgress();
      Promise.all(
        ids.map((id) => {
          const item = this.filterItems.find((a) => a.id == id);

          return removeWorkspaceRde({
            id: item.id,
            domainName: item.domainInfo.name,
          });
        }),
      )
        .then(() => {
          this.filterItems = this.filterItems.filter(
            (a) => !ids.includes(a.id),
          );
          CommonUIControl.ShowSuccessToast("Delete RDE is success");
        })
        .catch((err) => {
          console.error(err);
          CommonUIControl.ShowErrorToast(err?.message || err, 5000);
        })
        .finally(() => {
          CommonUIControl.HideUIProgress();
        });
    },
    onClickAdd() {
      this.$router.push(`/workspace/${this.workspaceId}/rde/create`);
    },
    updateSelectedItems(value) {
      this.selectedItems = value;
      this.$emit("updateSelectedRde", value);
    },
    handleClickFavorite() {
      this.isFavorite = !this.isFavorite;
    },
    onFavorite(id) {
      if (isNull(this.favoriteItems)) {
        this.favoriteItems = [];
      }

      const index = this.favoriteItems.indexOf(id);
      if (index > -1) {
        //remove
        this.favoriteItems.splice(index, 1);
      } else {
        //add
        this.favoriteItems?.push(id);
      }

      this.saveFavoritesToStorage();
      this.updateFavoriteOnViewArr();
    },
    saveFavoritesToStorage() {
      //get user name
      localStorage.setItem(
        this.userName + this.favoriteKey,
        JSON.stringify(this.favoriteItems),
      );
    },
    getFavoritesFromStorage() {
      const fvPlain = localStorage.getItem(this.userName + this.favoriteKey);
      this.favoriteItems = JSON.parse(fvPlain);
      this.updateFavoriteOnViewArr();
    },
    updateFavoriteOnViewArr() {
      //map favorite with items
      for (const i in this.baseItems) {
        if (this.favoriteItems?.includes(this.baseItems[i].id)) {
          this.baseItems[i].favorite = true;
        } else {
          this.baseItems[i].favorite = false;
        }
      }

      for (const i in this.filterItems) {
        if (this.favoriteItems?.includes(this.filterItems[i].id)) {
          this.filterItems[i].favorite = true;
        } else {
          this.filterItems[i].favorite = false;
        }
      }
    },
    viewStateRDE(rde) {
      this.selectedApp = {
        ...rde,
        projectName: rde.workspace?.projectName,
        profileName: rde.workspace?.profileName,
      };
    },
    viewResourceRDE(rde) {
      this.selectedResourceRDE = {
        rdeId: rde?.id,
        namespace: rde.namespace,
        rde: rde.name,
        wsName: rde?.workspace?.name,
      };
      this.showResourceRDE = true;
    },
    onRdeStatusError(params) {
      this.$emit("getting-rde-status-error", params);
    },
  },
  beforeMount() {
    this.workspaceId = this.$route.params.workspaceId;
    this.workspace = this.workspaceList.find(
      (item) => item.id == this.workspaceId,
    );
    this.searchFields = SEARCH_FIELDS;
    this.baseItems = this.items;
  },
};

const SEARCH_FIELDS = [{ value: "name", label: "Name" }];
</script>

<style lang="scss">
.app-rde-popup-detail {
  position: fixed;
  top: 50px;
  right: -50%;
  width: 0px;
  height: 0px;
  z-index: 5;
  transition: right 0.5s;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}
.app-rde-popup-detail-ative {
  width: 50%;
  height: calc(100% - 50px);
  right: 0px;
}
</style>
