<template>
  <div class="btn-status-wrapper">
    <FButton
      :disabled="disabled || status !== 'running'"
      :to="to"
      size="md"
      color="#131316"
      class="btn-status"
    >
      <SvgIcon
        :iconName="btType"
        :size="btType == 'vscode' ? 'sm' : 'md'"
        :color="
          disabled || status !== 'running' ? 'rgba(0, 0, 0, 0.26)' : '#000'
        "
        :disabled="disabled"
        class="mr-1"
      />
      <!-- {{ btName }} -->
      <span v-if="status" class="status-indicator" :style="{ color: btColor }"
        >&#x2022;</span
      >
      <span
        v-if="status && showText"
        class="status-text"
        :style="{ color: btColor }"
        >{{ btStatus }}</span
      >
      <!-- <v-progress-circular
        v-if="
          status == 'waiting' && podStatus !== POD_STATUS.running && !disabled
        "
        indeterminate
        :size="15"
        width="2"
        class="ml-2"
        color="#87888c"
      ></v-progress-circular> -->
    </FButton>
  </div>
</template>

<script>
import { POD_STATUS } from "@/service/constants";

const Status = {
  loading: "Loading...",
  waiting: "Waiting",
  running: "Running",
  terminated: "Terminated",
  disabled: "Disabled",
};

const btName = {
  vscode: "VS-Code",
  ssh: "SSH",
  jupyter: "Jupyter",
};
export default {
  props: {
    btType: { type: String },
    status: { type: String, default: "running" },
    to: { type: String },
    disabled: { type: Boolean, default: false },
    showText: { type: Boolean, default: false },
    podStatus: { type: String, default: POD_STATUS.created },
  },
  data() {
    return {
      POD_STATUS,
    };
  },
  computed: {
    btName() {
      return btName[this.btType];
    },
    btStatus() {
      if (this.disabled || this.status !== "running") {
        return "Disabled";
      } else {
        return Status[this.status];
      }
    },
    btColor() {
      if (this.disabled) {
        return "rgba(0, 0, 0, 0.26)";
      } else {
        switch (this.status) {
          case "waiting": {
            return "#FD7E14";
          }
          case "running": {
            return "#2EB85C";
          }
          case "terminated": {
            return "#E55353";
          }
          case "loading": {
            return "#E55353";
          }
          default:
            return "#00";
        }
      }
    },
  },
};
</script>

<style type="scss">
.btn-status-wrapper {
  .btn-status {
    background-color: #ffffff;
    text-transform: unset !important;
    min-width: 80px !important;
    padding: 0 10px !important;
  }
  .v-btn--outlined {
    border: thin solid #dadce0 !important;
  }

  .status-text {
    font-size: 14px;
  }

  .status-indicator {
    font-size: 28px;
  }
}
</style>
