
<v-switch
  v-model="checked"
  class="atoms__sw"
  :class="[appendClass]"
  :value="true"
  v-bind="$attrs"
  v-on="$listeners"
  @change="handleChange"
></v-switch>
