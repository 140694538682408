<template>
  <div class="w-full grid grid-cols-8 grid-flow-col">
    <div class="col-span-2 flex flex-col justify-center">
      <div class="add-on-info">
        {{ item?.workspace?.displayName || item?.workspace?.name }}
      </div>
      <div class="">
        <span class="workspace-name pr-1">{{
          item.displayName || item.name
        }}</span>
        <Tooltip
          v-if="item?.description"
          :text="item?.description"
          position="right"
          targetSelf
          contentClass="bg-black"
          class="pr-2"
        >
          <v-icon size="medium"> mdi-information-outline </v-icon>
        </Tooltip>
        <ItemStatus :status="podStatus" :item="item" :tooltip="tooltipMsg" />
      </div>
      <div class="add-on-info padding">
        Namespace: <span>{{ item?.namespace }}</span>
      </div>
      <div class="add-on-info padding">
        Username: <span class="add-on-detail">{{ item?.userName }}</span>
      </div>
    </div>
    <div class="wp-info col-span-3 grid grid-cols-3 gap-3">
      <div class="col-span-1 flex flex-col justify-center">
        <div class="flex flex-row gap-1">
          <SvgIcon iconName="cpu" size="sm" color="#3399FF" />
          <label>CPU</label>
        </div>
        <label class="content">
          {{
            `${item.currentUsage ? item.currentUsage.cpu + "/" : ""}${item.cpu}`
          }}
          {{ UNIT_LABELS.MiliCores }}
        </label>
      </div>
      <div class="col-span-1 flex flex-col justify-center">
        <div class="flex flex-row gap-1">
          <SvgIcon iconName="memory" size="sm" color="#3399FF" />
          <label>Memory</label>
        </div>
        <label class="content">
          {{
            `${item.currentUsage ? item.currentUsage.memory + "/" : ""}${
              item.memory
            }`
          }}
          {{ UNIT_LABELS.MebiByte }}
        </label>
      </div>
      <div class="col-span-1 flex flex-col justify-center">
        <div class="flex flex-row gap-1">
          <SvgIcon iconName="memory" size="sm" color="#3399FF" />
          <label>Disk</label>
        </div>
        <label class="content">
          {{
            `${item.currentUsage ? item.currentUsage.disk + "/" : ""}${
              item.disk
            }`
          }}
          {{ item.diskUnit }}
        </label>
      </div>
    </div>
    <div class="col-span-3 w-full flex flex-col justify-center">
      <div class="flex flex-row gap-2 p-2 w-full flex-wrap justify-end">
        <div class="icon-resource">
          <v-icon
            title="view resource detail"
            @click="() => viewResourceRDE(item)"
          >
            mdi-note-text-outline
          </v-icon>
        </div>
        <ButtonStatus
          :disabled="
            !item.serviceTypes.includes('vscode') ||
            podStatus !== POD_STATUS.running
          "
          btType="vscode"
          :status="listServiceStatus.vscodeserver"
          :to="`${buildURL(item)}/vscode`"
          :podStatus="podStatus"
        />
        <ButtonStatus
          :disabled="
            !item.serviceTypes.includes('webssh') ||
            podStatus !== POD_STATUS.running
          "
          btType="ssh"
          :status="listServiceStatus.sshserver"
          :to="`${buildURL(item)}/ssh`"
          :podStatus="podStatus"
        />
        <ButtonStatus
          :disabled="
            !item.serviceTypes.includes('notebook') ||
            podStatus !== POD_STATUS.running
          "
          btType="jupyter"
          :status="listServiceStatus.jupyter"
          :to="`${buildURL(item)}/notebook`"
          :podStatus="podStatus"
        />
      </div>
    </div>
    <div class="menu-btn flex flex-col justify-center">
      <div class="text-right pr-3 flex flex-row">
        <IconButton
          class="border-none"
          size="md"
          iconName="mdi-cached"
          color="#87888C"
          tooltip="Update Status"
          @click="() => reloadRdeStatus(item.id)"
        ></IconButton>
        <v-menu offset-y transition="scale-transition">
          <template v-slot:activator="{ on }">
            <IconButton
              class="border-none"
              size="md"
              iconName="mdi-dots-horizontal"
              color="#87888C"
              tooltip="Actions"
              v-on="on"
            ></IconButton>
          </template>
          <v-list>
            <v-list-item
              v-for="(menuItem, i) in Menus"
              :key="i"
              class="list-rde-menu-item py-1"
              :disabled="menuItem.disabled"
              @click="menuItem.action(item)"
            >
              <v-list-item-title>
                <div class="flex flex-row gap-1 items-center">
                  <v-icon
                    v-if="menuItem.icon"
                    :color="menuItem.disabled ? '#00000060' : menuItem.color"
                  >
                    {{ menuItem.icon }}
                  </v-icon>
                  <ImageIcon
                    v-if="menuItem.svgIcon"
                    :iconName="menuItem.svgIcon"
                  />
                  {{ menuItem.title }}
                </div>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </div>
  </div>
</template>

<script>
import ItemStatus from "../../../common/ItemStatus.vue";
import { RequestMixin } from "@/mixins/RequestMixin";
import { getRdeStatus } from "@/service/apis/workspaceApis";
import { UNIT_LABELS } from "@/service/constants";
import { POD_STATUS } from "@/service/constants";
import {
  updateRdeStatus,
  //removeWorkspaceRde,
  forceRestartRde,
} from "@/service/apis/workspaceApis";

const LIST_SERVICE = ["sshserver", "vscodeserver", "jupyter"];
const RdeStatus = {
  waiting: "waiting",
  running: "running",
  terminated: "terminated",
  restart: "restart",
};

const RELOAD_TIMEOUT = 20000;
const SHORT_RELOAD_TIMEOUT = 6000;

export default {
  props: {
    item: {
      type: [Array, Object],
    },
    namespaces: { type: String },
    workspaceName: { type: String },
    createdBy: { type: String },
    viewResourceRDE: { type: Function, default: () => undefined },
    viewStateRDE: { type: Function, default: () => undefined },
  },
  components: { ItemStatus },
  mixins: [RequestMixin],
  data() {
    return {
      podStatus: POD_STATUS.loading,
      listServiceStatus: {
        sshserver: RdeStatus.waiting,
        vscodeserver: RdeStatus.waiting,
        jupyter: RdeStatus.waiting,
      },
      tooltipMsg: "Loading...",
      UNIT_LABELS: UNIT_LABELS,
      POD_STATUS: POD_STATUS,
      isUpdating: false,
      menuItems: [
        {
          svgIcon: "grafana",
          title: "Dashboard",
          action: this.onMenuGrafana,
        },
        {
          icon: "mdi-laptop-mac",
          color: "#22659D",
          title: "Status",
          action: this.onViewRDEState,
        },
        {
          icon: "mdi-stop-circle-outline",
          color: "#E55353",
          title: "Stop",
          action: this.onStop,
          notShowOn: [POD_STATUS.stop, POD_STATUS.shutdown],
        },
        {
          icon: "mdi-power",
          color: "#F9B115",
          title: "Shutdown",
          action: this.onShutdown,
          notShowOn: [POD_STATUS.shutdown],
        },
        {
          icon: "mdi-play-circle-outline",
          color: "#0095AC",
          title: "Resume",
          action: this.onResume,
          showOn: [POD_STATUS.stop],
        },
        {
          icon: "mdi-restart",
          color: "#2EB85C",
          title: "Restart",
          action: this.onRestart,
          showOn: [POD_STATUS.shutdown],
        },
        {
          icon: "mdi-restart",
          color: "#FD7E14",
          title: "Force Restart",
          action: this.onForceRestart,
        },
        {
          icon: "mdi-pencil",
          color: "#321FDB",
          title: "Modification",
          action: this.onEditRDE,
          //showOn: ["owner"],
        },
        {
          icon: "mdi-trash-can-outline",
          color: "#646569",
          title: "Delete",
          action: this.onDelete,
          //showOn: ["owner"],
        },
      ],
      Menus: [],
    };
  },
  watch: {
    podStatus(status) {
      if (status) {
        this.Menus = this.filteredMenus(status, this.item?.workspace?.isOwner);
      }
    },
  },
  methods: {
    async fetchRdeStatus(force = false) {
      if (this.isUpdating && !force) {
        return false;
      }
      if (this.item) {
        const params = {
          id: this.item.id,
          name: this.item.ideConfigCrdName,
          namespace: this.item.namespace,
        };
        return new Promise((resolve, reject) => {
          getRdeStatus(params).then(
            (res) => {
              this.podStatus = res.data.status;
              const rdeStatus = res.data.rdeStatus;
              if (rdeStatus) {
                this.tooltipMsg = "";
                for (const i in LIST_SERVICE) {
                  const serviceName = LIST_SERVICE[i];
                  const serviceStatus = rdeStatus.find((item) => {
                    return item.name === serviceName;
                  });

                  if (serviceStatus) {
                    this.listServiceStatus[serviceName] = serviceStatus.status;
                    this.tooltipMsg += this.buildTooltipText(serviceStatus);
                  } else {
                    this.listServiceStatus[serviceName] = RdeStatus.waiting;
                    this.tooltipMsg = res.data.message || "";
                  }
                }
              }
              resolve(res.data.status);
            },
            (err) => {
              // In case getting rde status error
              this.podStatus = POD_STATUS.loading;
              this.$emit("getting-rde-status-error", params);
              reject(err);
            },
          );
        });
      }
    },
    async handleStatusItem(item, status, callback = null) {
      await this.mutation(
        () =>
          updateRdeStatus({
            rdeId: item.id,
            status,
          }),
        {
          onSuccess: () => {
            if (callback) {
              callback();
            }
          },
          onError: (err) => {
            if (callback) {
              callback();
            }
            console.log(err);
          },
        },
      );
    },
    async handleForceRestart(item) {
      await this.mutation(
        () =>
          forceRestartRde({
            rdeId: item.id,
          }),
        {
          onSuccess: () => {},
          onError: (err) => {
            console.log(err);
          },
        },
      );
    },
    buildTooltipText(container) {
      let tooltipStr = "";
      if (container) {
        if (container.status != RdeStatus.running) {
          tooltipStr = `- ${container.name || ""}: (${
            container.reason || ""
          } | ${container.message || ""})`;
        }
      }
      return tooltipStr + "\n\r";
    },
    buildURL(item) {
      const strUlr = `/remote-development-env/${item.userName}/${
        item.workspace?.name
      }/${item.name}/${item?.namespace}/${item.domainInfo.domainURL.replace(
        "https://",
        "",
      )}`;
      return strUlr;
    },
    async reloadRdeStatus() {
      await this.fetchRdeStatus();
    },
    forceSetStatus(status) {
      this.podStatus = status;
      this.keepReloadStatus();
    },
    keepReloadStatus() {
      this.isUpdating = true;
      this.attemptReload(4);
    },
    attemptReload(attemptsLeft) {
      if (attemptsLeft > 0) {
        setTimeout(() => {
          this.fetchRdeStatus(true);
          this.attemptReload(attemptsLeft - 1);
        }, SHORT_RELOAD_TIMEOUT);
      } else {
        this.isUpdating = false;
      }
    },
    filteredMenus(status, isOwner) {
      return this.menuItems.filter((item) => {
        if (item.notShowOn) {
          return !item.notShowOn?.includes(status);
        }

        if (item.showOn) {
          if (isOwner)
            return (
              item.showOn?.includes(status) || item.showOn?.includes("owner")
            );
          else
            return (
              !item.showOn?.includes("owner") && item.showOn?.includes(status)
            );
        }
        return true;
      });
    },
    // RDE Actions
    onMenuGrafana(item) {
      window.open(item?.dashboardUrl, "_blank");
    },
    onEditRDE() {
      const rdeId = this.item.id;
      if (rdeId) {
        const workspaceId =
          this.item?.workspace?.id || this.$route.params.workspaceId;
        this.$router.push(`/workspace/${workspaceId}/rde/${rdeId}/modify`);
      }
    },
    onViewRDEState(rdeObj) {
      if (rdeObj) {
        this.viewStateRDE(rdeObj);
      }
    },
    handleUpdateStatus(status) {
      // Right after updating status, keep reloading status every 5s (3 times)
      this.tooltipMsg = "";
      this.podStatus = status;
      this.handleStatusItem(this.item, status, this.keepReloadStatus);
    },
    onStop() {
      this.handleUpdateStatus(POD_STATUS.stop);
    },
    onShutdown() {
      this.handleUpdateStatus(POD_STATUS.shutdown);
    },
    onResume() {
      this.handleUpdateStatus(POD_STATUS.resume);
    },
    onRestart() {
      this.handleUpdateStatus(POD_STATUS.restart);
    },
    onForceRestart() {
      this.podStatus = POD_STATUS.restarting;
      this.handleForceRestart(this.item);
      this.keepReloadStatus();
    },
    onDelete(item) {
      this.$emit("click:remove", item);
    },
    checkModification() {
      const rdeId = this.$route.params.updatedId;
      if (rdeId && rdeId === this.item.id) {
        this.keepReloadStatus();
      }
    },
    //End RDE Actions
  },
  mounted() {
    this.interval = setInterval(() => {
      this.fetchRdeStatus();
    }, RELOAD_TIMEOUT);
  },
  async beforeMount() {
    // Getting RDE status at the first load, if the status is Pending -> keep reloading status every 5s (3 times)
    const status = await this.fetchRdeStatus();
    if (status && status == POD_STATUS.pending) {
      this.keepReloadStatus();
    }

    // Check if the RDE is being modified -> keep reloading status every 5s (3 times)
    this.checkModification();
  },
  beforeDestroy() {
    if (this.interval) {
      clearTimeout(this.interval);
    }
  },
};
</script>
<style type="scss" scoped>
.icon-resource {
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid #dadce0;
  padding-right: 20px;
  margin-right: 15px;
  .v-icon::after {
    display: none;
  }

  button {
    border: 1px solid #dadce0;
    border-radius: 4px;
    padding: 5px 8px;
    color: black;
  }
}

.add-on-info {
  font-size: 12px;
  font-weight: 400;
  line-height: 11px;
  letter-spacing: 0.0015em;
  text-align: left;
  color: #646569;
}

.add-on-info > span {
  font-weight: bold;
}

.padding {
  padding: 2px 0;
}
</style>
