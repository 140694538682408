
<v-data-table
  :headers="headers"
  :items="data"
  hide-default-footer
  dense
  disable-pagination
  class="table__wrapper_resource_rde"
>
  <template v-slot:[`item.serviceType`]="{ item }">
    <td class="header-table">
      {{ item.serviceType }}
    </td>
  </template>
</v-data-table>
