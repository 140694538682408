
<div class="btn-status-wrapper">
  <FButton
    :disabled="disabled || status !== 'running'"
    :to="to"
    size="md"
    color="#131316"
    class="btn-status"
  >
    <SvgIcon
      :iconName="btType"
      :size="btType == 'vscode' ? 'sm' : 'md'"
      :color="
        disabled || status !== 'running' ? 'rgba(0, 0, 0, 0.26)' : '#000'
      "
      :disabled="disabled"
      class="mr-1"
    />
    <!-- {{ btName }} -->
    <span v-if="status" class="status-indicator" :style="{ color: btColor }"
      >&#x2022;</span
    >
    <span
      v-if="status && showText"
      class="status-text"
      :style="{ color: btColor }"
      >{{ btStatus }}</span
    >
    <!-- <v-progress-circular
      v-if="
        status == 'waiting' && podStatus !== POD_STATUS.running && !disabled
      "
      indeterminate
      :size="15"
      width="2"
      class="ml-2"
      color="#87888c"
    ></v-progress-circular> -->
  </FButton>
</div>
