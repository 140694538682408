import { deepCopy } from "@/service/topologyHelper";
import { roundNumber } from "@/pages/dashboard/helper.js";
import { UNIT_LABELS } from "@/service/constants";

export default {
  install(Vue) {
    Vue.prototype.$helper = {
      deepCopy,
      hex2rgba,
      byteToMebibyte,
      coreToMilicore,
      roundNumber,
      byteToGibibyte,
      convertUnits,
    };
  },
};

if (window) {
  /** 기존 사용하던 `require`의 폴리필 */
  window.require = (path) => {
    const replacePath = alias
      .filter(([reg]) => reg.test(path))
      .reduce((acc, [reg, replace]) => acc.replace(reg, replace), path);

    return replacePath;
  };
}

const alias = [
  [/^@\//, new URL("../../src", import.meta.url).href + "/"],
  [
    /^~\//,
    new URL("../../src/pages/main/modernframework", import.meta.url).href + "/",
  ],
];

const STEP = 1024;

const hex2rgba = (hex, alpha = 1) => {
  const [r, g, b] = hex.match(/\w\w/g).map((x) => parseInt(x, 16));
  return `rgba(${r},${g},${b},${alpha})`;
};

const byteToMebibyte = (bytes, toFixed = 2) => {
  return roundNumber(bytes / (STEP * STEP), toFixed);
};

const coreToMilicore = (cores, toFixed = 2) => {
  return roundNumber(cores * 1000, toFixed);
};

const byteToGibibyte = (bytes, toFixed = 2) => {
  return roundNumber(bytes / (STEP * STEP * STEP), toFixed);
};

const convertUnits = (baseUnit, value, valueUnit) => {
  // Convert value to bytes first
  let valueInBytes;
  switch (valueUnit) {
    case UNIT_LABELS.Byte:
      valueInBytes = value;
      break;
    case UNIT_LABELS.MebiByte:
      valueInBytes = value * STEP * STEP;
      break;
    case UNIT_LABELS.GibiByte:
      valueInBytes = value * STEP * STEP * STEP;
      break;
    default:
      throw new Error("Unsupported value unit");
  }

  // Convert bytes to the base unit
  switch (baseUnit) {
    case UNIT_LABELS.Byte:
      return roundNumber(valueInBytes);
    case UNIT_LABELS.MebiByte:
      return roundNumber(valueInBytes / (STEP * STEP));
    case UNIT_LABELS.GibiByte:
      return roundNumber(valueInBytes / (STEP * STEP * STEP));
    default:
      throw new Error("Unsupported base unit");
  }
};
