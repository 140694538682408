
<div class="p-0 m-0">
  <Select
    v-model="values.webssh.permission.role"
    :items="roleItems"
    :rules="[rules.required]"
    label="* Role"
    appendClass="select-v2"
    :solo="false"
  ></Select>
  <Select
    v-model="values.webssh.permission.scope"
    :items="scopeItems"
    :rules="[rules.required]"
    label="* Scope"
    appendClass="select-v2"
    :solo="false"
  ></Select>
  <!-- <Input
    v-model="values.webssh.permission.serviceAccountName"
    :rules="[rules.required]"
    label="Service Account Name"
    appendClass="input-text-field-v2"
  /> -->
</div>
