<template>
  <div>
    <div class="indicator-wrapper flex flex-row px-4">
      <div v-if="showPercent && percent >= 0" class="pc-value text-center">
        {{ percent }}%
      </div>
      <div v-if="showPercent && percent >= 0" class="percentage pr-2">
        <Doughnut
          :data="chartData"
          :options="chartOptions"
          width="45"
          height="45"
        />
      </div>
      <div class="indicator w-full pr-2" :class="titleClass">
        <div class="text-sm ititle w-auto">{{ title }}</div>
        <div class="">
          <span class="font-bold ivalue">{{ value }}</span>
          <span v-if="limitNumber >= 0" class="font-bold ivalue"
            >/{{ limitNumber }}</span
          >
          <span class="pl-2 text-sm ititle">{{ unit }}</span>
        </div>
      </div>
      <div
        v-if="splitter"
        class="splitter w-[2px] border-r float-right ml-1"
      ></div>
    </div>
  </div>
</template>

<script>
import { Doughnut } from "vue-chartjs";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale,
} from "chart.js";
ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale);

export default {
  props: {
    title: { type: String },
    value: { type: [Number, String], default: 0 },
    unit: { type: String },
    splitter: { type: Boolean, default: false },
    titleClass: { type: String },
    showPercent: { type: Boolean, default: false },
    limit: { type: [Number, String] },
  },
  components: { Doughnut },
  data() {
    return {
      chartOptions: {
        responsive: false,
        maintainAspectRatio: false,
        cutout: 17,
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            enabled: false,
          },
          datalabels: false,
        },
        elements: {
          arc: {
            borderWidth: 0,
          },
        },
      },
    };
  },
  computed: {
    chartData() {
      return {
        labels: ["1", "2"],
        datasets: [
          {
            backgroundColor: [this.color, "#E5E6EB"],
            data: [this.percent, 100 - this.percent],
          },
        ],
      };
    },
    color() {
      return this.percent <= 60
        ? "#2EB85C"
        : this.percent < 80
        ? "#F9B115"
        : "#bd2130";
    },
    limitNumber() {
      const limit = Number(this.limit);
      return !isNaN(limit) && limit !== 0 ? limit : -1;
    },
    percent() {
      return this.calPercent(this.value, this.limitNumber) || 0;
    },
  },
  methods: {
    calPercent(use, limit) {
      // Simplified validation and calculation
      use = parseFloat(use);
      limit = parseFloat(limit);

      if (isNaN(use) || isNaN(limit) || limit === 0) return 0;

      const percent = (use / limit) * 100;
      return percent > 100
        ? 100
        : percent <= 9
        ? this.$helper.roundNumber(percent, 1)
        : Math.round(percent);
    },
  },
};
</script>

<style type="scss" scoped>
.indicator-wrapper {
  padding: 14px 0;
  position: relative;
  .pc-value {
    position: absolute;
    top: 29px;
    color: #1d2129;
    width: 45px;
    font-family: Helvetica Neue;
    font-size: 12px;
    font-weight: bold;
    line-height: 15.13px;
  }
  .indicator {
    min-width: 120px;
    .ititle {
      color: #87888c;
      font-size: 12px;
    }
    .ivalue {
      color: #321fdb;
      font-family: Roboto;
      font-size: 28px;
      font-weight: 600;
      line-height: 28px;
      letter-spacing: 0.0015em;
      text-align: left;
    }
  }
  .splitter {
    content: "";
  }
}
</style>
