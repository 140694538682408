<template>
  <v-data-table
    :headers="headers"
    :items="data"
    hide-default-footer
    dense
    disable-pagination
    class="table__wrapper_resource_rde"
  >
    <template v-slot:[`item.serviceType`]="{ item }">
      <td class="header-table">
        {{ item.serviceType }}
      </td>
    </template>
  </v-data-table>
</template>

<script>
import CommonUIControl from "@/helper/CommonUIControl";
import {
  getWorkspaceRdeById,
  getRDEStateMetrics,
} from "@/service/apis/workspaceApis";
import { isEmpty, maxBy } from "lodash";
import { roundNumber } from "../dashboard/helper";

export default {
  props: { selectedResourceRDE: { type: Object, default: () => null } },
  data: () => ({
    headers: [
      {
        text: "",
        sortable: false,
        value: "serviceType",
        class: "header-table",
      },
      {
        text: "CPU",
        sortable: false,
        value: "cpu",
        class: "header-table",
      },
      {
        text: "Memory",
        sortable: false,
        value: "memory",
        class: "header-table",
      },
      {
        text: "Disk",
        sortable: false,
        value: "disk",
        class: "header-table",
      },
    ],
    data: [],
    matchingKeys: {
      vscode: "vscodeserver",
      webssh: "sshserver",
      notebook: "jupyter",
    },
  }),
  methods: {
    excludeUnitText(value) {
      const CHARACTERS_TO_REMOVE = ["m", "mi", "Gi", "Mi", "gi"];

      if (typeof value === "string") {
        CHARACTERS_TO_REMOVE.forEach((str) => {
          value = value.replace(new RegExp(str, "g"), "");
        });
        return value;
      }
      return value;
    },
    // currently grafana returns one object with key as timestamp and value as the metric value
    // we would like to get the latest value from the object
    getLastestValueFromObj(obj) {
      if (isEmpty(obj)) {
        return 0;
      }

      const maxKey = maxBy(Object.keys(obj), (v) => new Date(v).getTime());
      if (maxKey) return parseFloat(obj[maxKey]) || 0;
      return 0;
    },
    transformValue({ rdeMeasure: rdeMeasureObj, rdeDetail, serviceType }) {
      // select measure object based on service type (vscode, webssh, notebook)
      const rdeMeasure = rdeMeasureObj?.containers?.find(
        (c) => c?.name === this.matchingKeys[serviceType],
      );

      if (rdeMeasure) {
        // convert core to milicore
        const currentCPU = roundNumber(
          this.getLastestValueFromObj(rdeMeasure.cpu) * 1000,
          2,
        );

        // convert byte to Mi
        const currentMemory = roundNumber(
          this.getLastestValueFromObj(rdeMeasure.memory) / 1048576,
          2,
        );

        // disk includes both diskRead and diskWrite.
        // convert byte to Gi
        const currentDisk = roundNumber(
          (this.getLastestValueFromObj(rdeMeasure.diskRead) +
            this.getLastestValueFromObj(rdeMeasure.diskWrite)) /
            1073741824,
          2,
        );

        return {
          cpu: `${currentCPU}/${this.excludeUnitText(
            rdeDetail?.[serviceType]?.resourceSize.cpu,
          )} mi`,
          memory: `${currentMemory}/${this.excludeUnitText(
            rdeDetail?.[serviceType]?.resourceSize.memory,
          )} Mi`,
          disk: `${currentDisk}/${this.excludeUnitText(
            rdeDetail?.diskSize?.disk,
          )} Gi`,
        };
      }
      return {
        cpu: `0/${this.excludeUnitText(
          rdeDetail?.[serviceType]?.resourceSize.cpu,
        )} mi`,
        memory: `0/${this.excludeUnitText(
          rdeDetail?.[serviceType]?.resourceSize.memory,
        )} Mi`,
        disk: `0/${this.excludeUnitText(rdeDetail?.diskSize?.disk)} Gi`,
      };
    },
    fetchData() {
      if (!isEmpty(this.selectedResourceRDE)) {
        CommonUIControl.ShowUIProgress();

        Promise.all([
          getWorkspaceRdeById({ rdeId: this.selectedResourceRDE.rdeId }),
          getRDEStateMetrics({
            rdeId: this.selectedResourceRDE.rdeId,
            namespace: this.selectedResourceRDE.namespace,
            rde: this.selectedResourceRDE.rde,
            wsName: this.selectedResourceRDE.wsName,
          }),
        ])
          .then((res) => {
            const rdeDetail = res?.[0]?.data;
            const rdeMeasure = res?.[1]?.result;

            if (!rdeDetail || !rdeMeasure) {
              throw new Error("Resource detail or measure is empty");
            }

            const data = [];

            if (rdeDetail?.vscode?.resourceSize) {
              data.push({
                serviceType: "VS Code",
                ...this.transformValue({
                  rdeMeasure,
                  rdeDetail,
                  serviceType: "vscode",
                }),
              });
            }

            if (rdeDetail?.webssh?.resourceSize) {
              data.push({
                serviceType: "SSH",
                ...this.transformValue({
                  rdeMeasure,
                  rdeDetail,
                  serviceType: "webssh",
                }),
              });
            }

            if (rdeDetail?.notebook?.resourceSize) {
              data.push({
                serviceType: "Jupiter",
                ...this.transformValue({
                  rdeMeasure,
                  rdeDetail,
                  serviceType: "notebook",
                }),
              });
            }
            this.data = data;
          })
          .catch((e) => {
            CommonUIControl.ShowErrorToast(
              e?.message || "Fetch resource detail failed",
              5000,
            );
          })
          .finally(() => {
            CommonUIControl.HideUIProgress();
          });
      }
    },
  },
  mounted() {
    this.fetchData();
  },
};
</script>

<style lang="scss">
.table__wrapper_resource_rde {
  & .header-table {
    background-color: #f0f1f7;
    font-size: 12px !important;
    font-weight: 500 !important;
    // color: #00000099 !important;
  }
  & .text-start {
    color: black;
    font-size: 12px !important;
    font-weight: 400;
    text-align: start;
  }
}
</style>
