
<div class="p-0 m-0">
  <div class="header">
    <div class="left-header">
      <img
        src="~@/assets/img/arrow-back.svg"
        alt="SVG Icon"
        :style="{ cursor: 'pointer' }"
        @click="goBack"
      />
      <span>{{ mode === "EDIT" ? "Edit RDE" : "Create new RDE" }}</span>
    </div>

    <div class="right-header">
      <div v-if="adminAuth && mode === 'CREATE'" class="switch-v2-group">
        <Sw
          v-model="values.showCreateUser"
          inset
          appendClass="switch-v2"
        ></Sw>
        <span class="text-switch">Create User/Group RDE</span>
      </div>
      <Button :size="'md'" @click="goBack">Cancel</Button>
      <Button :type="'submit'" color="primary" :size="'md'" text fill>
        Apply
      </Button>
    </div>
  </div>
  <div class="rde-section">
    <div class="grid grid-cols-2">
      <Input
        v-model="values.appName"
        label="* RDE Name"
        appendClass="input-text-field-v2"
        :rules="[rules.required, rules.name]"
        :disabled="mode === 'EDIT'"
      />
      <Input
        v-model="values.displayName"
        label="* Display Name"
        appendClass="input-text-field-v2"
        :rules="[rules.required]"
      />
    </div>

    <Input
      v-model="values.description"
      label="Description"
      appendClass="input-text-field-v2"
    />
    <Select
      v-model="values.namespace"
      :items="namespace_items"
      :rules="[rules.required]"
      :disabled="mode === 'EDIT'"
      label="* Namespace"
      appendClass="select-v2"
      :solo="false"
    ></Select>

    <!-- <NumberInput
      v-model="values.replicas"
      appendClass="input-text-field-v2"
      label="* Replicas"
      :format="'none'"
    /> -->
    <div class="grid grid-cols-3">
      <InfraSelect
        :infraBaselineList="infraBaselineList"
        :values="values"
        :onChangeInfra="onChangeInfraRDE"
        :defaultValue="defaultValueInfra"
      />
      <NumberInput
        v-model="values.diskSize.disk"
        label="Disk - GiB"
        :format="'none'"
        appendClass="input-text-field-v2"
        :rules="[rules.required, maxDiskValidate]"
        :disabled="mode === 'EDIT'"
        :max="50"
      />
      <Select
        v-model="values.diskSize.type"
        :items="storage_type_items"
        :rules="[rules.required]"
        label="*Storage Type"
        appendClass="select-v2"
        :solo="false"
      ></Select>
    </div>
    <v-autocomplete
      v-model="values.tolerations"
      :items="node_group_items"
      item-text="value"
      :item-value="mapValueNodeGroup"
      label="* Node Taint"
      small-chips
      placeholder="Select Node Taint"
      class="app-g-autocomplete-v2"
      clearable
      deletable-chips
      multiple
      outlined
      dense
      :rules="[rules.required]"
    />
  </div>
  <div v-if="values.serviceTypes" class="rde-section">
    <div class="rde-title">Service Type</div>
    <div class="service-type">
      <div class="switch-v2-group">
        <Sw
          :value="values.serviceTypes.includes('vscode')"
          inset
          appendClass="switch-v2"
          @input="
            (value) =>
              setServiceTypes({ setValue, values, name: 'vscode', value })
          "
        ></Sw>
        <span class="text-switch">Visual Studio Code</span>
      </div>
      <div class="switch-v2-group">
        <Sw
          :value="values.serviceTypes.includes('webssh')"
          inset
          appendClass="switch-v2"
          @input="
            (value) =>
              setServiceTypes({ setValue, values, name: 'webssh', value })
          "
        ></Sw>
        <span class="text-switch">SSH</span>
      </div>
      <div class="switch-v2-group">
        <Sw
          :value="values.serviceTypes.includes('notebook')"
          inset
          appendClass="switch-v2"
          @input="
            (value) =>
              setServiceTypes({
                setValue,
                values,
                name: 'notebook',
                value,
              })
          "
        ></Sw>
        <span class="text-switch">Jupyter</span>
      </div>
    </div>
    <div v-if="!values.serviceTypes.length" class="text-xs text-error h-5">
      Require at least one service.
    </div>
  </div>

  <div class="rde-section">
    <div class="rde-title">Port List</div>
    <AddPortInputListV2
      :portList="values.portList"
      @change="
        (newPorts) => {
          setValue('portList', newPorts);
        }
      "
    />
  </div>

  <div
    v-if="values.serviceTypes && values.serviceTypes.includes('vscode')"
    class="rde-section"
  >
    <div class="rde-title">Visual Studio Code Setting</div>
    <VSCodeInput :values="values" :set-value="setValue" />
    <InfraSizeInput
      serviceType="vscode"
      :values="values"
      :requirementInfraResource="guideInfraResource?.requirements"
      :validate="validate"
    />
  </div>

  <div
    v-if="values.serviceTypes && values.serviceTypes.includes('webssh')"
    class="rde-section"
  >
    <div class="rde-title">Web SSH Setting</div>
    <WebSSHInput :values="values" :adminAuth="adminAuth" />
    <InfraSizeInput
      serviceType="webssh"
      :values="values"
      :requirementInfraResource="guideInfraResource?.requirements"
      :validate="validate"
    />
  </div>

  <div
    v-if="values.serviceTypes && values.serviceTypes.includes('notebook')"
    class="rde-section"
  >
    <div class="rde-title">Jupyter Setting</div>
    <InfraSizeInput
      serviceType="notebook"
      :values="values"
      :requirementInfraResource="guideInfraResource?.requirements"
      :validate="validate"
    />
  </div>

  <div v-if="values.showCreateUser" class="rde-section">
    <div class="rde-title">Create Multiple RDE</div>
    <UserInput :values="values" :setValue="setValue" :workspace="workspace" />
  </div>
</div>
