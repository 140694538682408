export const transformData = (data) => {
  return data;
};

export const transformOptions = (options) => {
  return {
    responsive: true,
    maintainAspectRatio: true,
    tooltips: {
      enabled: false,
    },
    plugins: {
      legend: false,
      datalabels: {
        labels: {
          title: {
            font: {
              weight: "bold",
              size: 14,
            },
          },
        },
        color: "white",
      },
    },
    ...options,
  };
};

// this is plugin supporting text center in doughnut
const textCenterPlugin = {
  id: "textCenterPlugin",
  afterDraw: function (chartInstance) {
    if (chartInstance?.config?._config?.options?.textCenter) {
      const text =
        chartInstance?.config?._config?.options?.textCenter(chartInstance);

      const ctx = chartInstance.ctx;
      const width = chartInstance.width;
      const height = chartInstance.height;

      ctx.restore();
      ctx.font = "600 32px Arial";
      ctx.textBaseline = "middle";

      const metrics = ctx.measureText(text);

      const actualHeight =
        metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent;

      const textX = Math.round((width - metrics.width) / 2);

      ctx.fillText(text, textX, height / 2 + actualHeight / 4);
      ctx.save();
    }
  },
};

export const transformPlugins = (plugins) => {
  // We can set some default plugins that be suitable for amdb
  return [...(plugins || []), textCenterPlugin];
};
