
<div>
  <div class="indicator-wrapper flex flex-row px-4">
    <div v-if="showPercent && percent >= 0" class="pc-value text-center">
      {{ percent }}%
    </div>
    <div v-if="showPercent && percent >= 0" class="percentage pr-2">
      <Doughnut
        :data="chartData"
        :options="chartOptions"
        width="45"
        height="45"
      />
    </div>
    <div class="indicator w-full pr-2" :class="titleClass">
      <div class="text-sm ititle w-auto">{{ title }}</div>
      <div class="">
        <span class="font-bold ivalue">{{ value }}</span>
        <span v-if="limitNumber >= 0" class="font-bold ivalue"
          >/{{ limitNumber }}</span
        >
        <span class="pl-2 text-sm ititle">{{ unit }}</span>
      </div>
    </div>
    <div
      v-if="splitter"
      class="splitter w-[2px] border-r float-right ml-1"
    ></div>
  </div>
</div>
