
<section>
  <div class="flex flex-col">
    <div class="w-full ml-1">
      <SearchBar
        :onSearch="onSearch"
        :searchFields="searchFields"
        :isFavorite="isFavorite"
      >
        <div class="flex flex-row gap-2 mr-2">
          <FButton
            v-if="showFavoriteBtn"
            size="md"
            fill
            color="white"
            class="text-black"
            @click="() => handleClickFavorite()"
          >
            <v-icon v-if="isFavorite">mdi-star</v-icon>
            <v-icon v-else>mdi-star-outline</v-icon>
            Show Favorite
          </FButton>
          <FButton
            v-if="showSelectBtn"
            :disabled="selectedItems.length <= 0"
            size="md"
            color="primary"
            @click="() => onClickDelete()"
          >
            <v-icon>mdi-delete</v-icon>
            Delete
          </FButton>
          <FButton
            v-if="showAddBtn"
            size="md"
            fill
            depressed
            color="primary"
            @click="() => onClickAdd()"
          >
            <v-icon>mdi-plus</v-icon>
            Add RDE
          </FButton>
        </div>
      </SearchBar>
    </div>
    <div class="p-1">
      <WebIdeTable
        :items="filterItems"
        :showFavoriteBtn="showFavoriteBtn"
        :showSelectBtn="showSelectBtn"
        :onFavorite="onFavorite"
        :viewStateRDE="viewStateRDE"
        :viewResourceRDE="viewResourceRDE"
        @click:remove="handleRemoveItem"
        @click:stop="handleStatusItem"
        @click:resume="handleStatusItem"
        @click:shutdown="handleStatusItem"
        @click:restart="handleStatusItem"
        @selectedws="updateSelectedItems"
        @click:forceRestart="handleForceRestart"
        @getting-rde-status-error="onRdeStatusError"
      />
    </div>
  </div>
  <div
    :class="{
      'app-rde-popup-detail': true,
      'app-rde-popup-detail-ative': !!selectedApp,
    }"
  >
    <RDELogPopup
      v-if="!!selectedApp"
      :turnOffFunc="() => (selectedApp = null)"
      :selectedApp="selectedApp"
    />
  </div>
  <keep-alive>
    <Dialog
      v-model="showResourceRDE"
      persistent
      title="Resource Detail"
      max-width="700"
      @input="() => (selectedResourceRDE = null)"
    >
      <ResourceDetailRDE
        v-if="!!selectedResourceRDE"
        :selectedResourceRDE="selectedResourceRDE"
      />
    </Dialog>
  </keep-alive>
</section>
