<template>
  <div class="py-1 flex flex-row flex-start flex-wrap gap-4 bg-white">
    <div class="grid grid-cols-4 gap-1">
      <Indicator
        title="CPU Usage"
        :value="usageInfo?.cpu"
        :unit="UNIT_LABELS.MiliCores"
        :limit="wsInfo?.limitCpu"
        :showPercent="true"
      />
      <Indicator
        title="Memory Usage"
        :value="usageInfo?.memory"
        :unit="UNIT_LABELS.MebiByte"
        :limit="wsInfo?.limitMemory"
        :showPercent="true"
      />
      <Indicator
        title="Disk Usage"
        :value="usageInfo?.disk"
        :unit="UNIT_LABELS.GibiByte"
        :limit="wsInfo?.limitDisk"
        :showPercent="true"
      />
    </div>
  </div>
</template>

<script>
import Indicator from "@/components/newWorkspace/common/Indicator.vue";
import { UNIT_LABELS } from "@/service/constants";

export default {
  props: {
    workspaceId: { type: String },
    wsInfo: { type: Object },
    usageInfo: { type: Object },
  },
  components: { Indicator },
  data() {
    return {
      wp: null,
      UNIT_LABELS: UNIT_LABELS,
    };
  },
};
</script>
