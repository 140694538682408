
<div>
  <label class="label-infra-size"> Infra Size</label>
  <div class="grid grid-cols-3 gap-x-2">
    <NumberInput
      v-model="values[serviceType].resourceSize.cpu"
      label="CPU - milicores"
      :format="'none'"
      appendClass="input-text-field-v2"
      :rules="[rules.required, validateTotalSize('cpu')]"
      @blur="validate"
    />
    <NumberInput
      v-model="values[serviceType].resourceSize.memory"
      label="Memory - MiB"
      :format="'none'"
      appendClass="input-text-field-v2"
      :rules="[rules.required, validateTotalSize('memory')]"
      @blur="validate"
    />
  </div>
</div>
