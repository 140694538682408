
<PageTemplate
  :useCard="false"
  padding="p-0"
  :breadcrumbs="[
    {
      text: 'RDE Containers',
    },
  ]"
>
  <div class="">
    <TopRdeStatusBar
      :workspaceId="workspaceId"
      :wsInfo="wsInfo"
      :usageInfo="usageInfo"
    />
  </div>
  <div class="m-1 mt-4">
    <WebIdeList
      :items="rdeItems"
      :showFavoriteBtn="true"
      :favoriteKey="fvKey"
      :viewStateRDE="viewStateRDE"
      @getting-rde-status-error="onRdeStatusError"
    />
  </div>
  <div
    :class="{
      'app-rde-popup-detail': true,
      'app-rde-popup-detail-ative': !!selectedApp,
    }"
  >
    <RDELogPopup
      v-if="!!selectedApp"
      :turnOffFunc="() => (selectedApp = null)"
      :selectedApp="selectedApp"
    />
  </div>
</PageTemplate>
